<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="데이터를 업로드 하는 중입니다."
  >
    <!-- <GNB @openModal="openModal" /> -->

    <section class="contWrap sensor">
      <article class="bottom" v-if="smartSensor1.length > 0">
        <div class="card" v-for="(item, y) in smartSensor1" :key="y + 'y'">
          <h3>{{ item.apiName }}</h3>
          <div class="chartBox">
            <p class="date">
              <span>{{item.startDate}}</span>
              <span>~ {{item.endDate}}</span>
            </p>
            <LineChart4 :infoData="item.magnitude" :xData="item.Hz" />
          </div>
        </div>
      </article>
      <article class="bottom" v-else>
        <div class="card" >
          <h1>
          등록된 API가 없습니다
          </h1>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LineChart4 from "@/components/charts/LineChart4";
import moment from "moment";

export default {
  components: {
    LineChart4,
  },
  data() {
    return {
      loading: false,
      moment: moment,
      smartSensor1: [],
    };
  },
  computed: {
    ...mapState(["navbarState", "smartSensor"]),
  },
  watch: {
    smartSensor: function (newVal) {
      this.Sensorfunc(newVal, 1);
    },
  },
  created() {
    this.fetchData();
    this.$store.dispatch("SET_NAV_INDEX", "5");
  },
  mounted() {},
  methods: {
    /**
     * 페이지 생성시 데이터 트리거 기능
     */
    fetchData() {
      this.Sensorfunc(this.smartSensor, 1);
    },
    /**
     * 실시간 센서 트렌드 정보 변환 후 재배포 기능
     */
    Sensorfunc(newVal, num) {
      let smartSensor1Temp = [];
      newVal.map((item, i) => {
        let temp1 = [];
        let temp2 = [];
        let timeSort = item.data.sort((a, b) => {
          if (b.time < a.time) return 1;
          if (b.time > a.time) return -1;
          return 0;
        });
        timeSort.map((dt) => {
          if(Object.keys(dt).length == 2) {
            let dateFormat = dt.time.split("T");
            let dateFormat2 = dateFormat[0].split("-");
            temp1.push(dt.avg_freq);
            temp2.push(`${dateFormat2[1]}-${dateFormat2[2]} ${dateFormat[1].split(".")[0]}`);
          } else if (Object.keys(dt).length == 3) {
            temp1.push(dt.magnitude);
            temp2.push(String(dt.Hz.toFixed(10)));
          }
        });
        let dateFormat = item.data[0].time.split("T");
        let dateFormat2 = item.data[item.data.length - 1].time.split("T");
        smartSensor1Temp[i] = {
          magnitude: temp1,
          Hz: temp2,
          apiName: item.apiName,
          startDate: `${dateFormat[0]} ${dateFormat[1].split(".")[0]}`,
          endDate: `${dateFormat2[0]} ${dateFormat2[1].split(".")[0]}`,
        };
      });
      if (num == 1) {
        this.smartSensor1 = smartSensor1Temp;
      } else if (num == 2) {
        this.localSensor1 = smartSensor1Temp;
      }
    },
  },
};
</script>
